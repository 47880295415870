import van from './van.png';
import fb from './fb.png';
import logo from './Assets/logo.png';

import './App.css';

function App() {
  return (
    <div className="App">

        <img src={logo} className="logo" alt="logo" />

        <img src={van} className="App-logo" alt="logo" />

        


        <h1 style={{fontSize: 22}}>Coming soon</h1>

        <a href="https://www.facebook.com/mocognis" target='_blank'>
          <img src={fb} className="social" alt="logo" />
        </a>

    </div>
  );
}

export default App;
